import { Link } from 'react-router-dom';
// react icons 
import { TbWorldWww } from "react-icons/tb";
import { TbSettingsSearch } from "react-icons/tb";
import { GiServerRack } from "react-icons/gi";
import { FaRobot } from "react-icons/fa";
import { FaHandPointUp } from "react-icons/fa";


import Aos from "aos";
import "aos/dist/aos.css";

import '../styles/home.css';
import '../styles/homeMobile.css'
import { useEffect, useState } from 'react';
import Footer from './footer';


export default function Home() {
    const [scroll, setScroll] = useState(false)



    useEffect(() => {
        Aos.init();
    }, []);

    const onScroll = () => {
        if (window.scrollY >= 400) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener("scroll", onScroll);
    })

    const onClickScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    };

    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoader(false)
        }, 3500)
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            {
                loader ? (
                    <div className="loader-container">
                        <div className="lds-ripple"><div></div><div></div></div>
                    </div>
                ) :
                    (
                        <>
                            <div className="home-container">
                                <div className="section-one">
                                    <div className="element-right"></div>
                                    <div className='block'>
                                        <div className="section-one-text-container">
                                            <div className="shadow-box"></div>
                                            <h1>
                                                Zamonaviy biznes uchun <span>Veb saytlar yoki Telegram bot</span> yaratish xizmati
                                            </h1>
                                            <br />
                                            <p>
                                                Mijozlarni jalb qilishda yoki biznesingizni avtomatlashtirishda qiyinchilikka duch kelayapsizmi? Biz sizga zamonaviy veb-saytlar va Telegram botlar orqali bularning barchasini hal qilishda yordam beramiz! <br /> <br />
                                                Bizning xizmatlarimiz biznesingizni yanada ko‘proq mijozlar bilan bog‘lashga, savdo jarayonlarini avtomatlashtirishga va vaqtni tejashga yordam beradi.
                                            </p>
                                            <div className="section-one-btns">
                                                <Link to={'/contact'}>Kontakt</Link>
                                                <Link to={'/services'}>Ba'tafsil</Link>
                                            </div>
                                        </div>
                                        <div className="section-one-image-container">
                                            <div className="bg-circel"></div>
                                            <div className="laptop-icon"></div>
                                            <div className="tg-bot-icon"></div>
                                            <div className="anime_user_image">

                                            </div>
                                            <div className="vs-code-icon"></div>
                                        </div>
                                        <div className="element-left"></div>
                                    </div>
                                </div>

                                <div className="block-bottom">
                                    <div className="block-bottom-card">
                                        <div className="bottom-card">
                                            <TbWorldWww />
                                        </div>
                                        <div className="bottom-text">
                                            <h3>Veb Saytlar</h3>
                                            <p>Sizning biznesingizning raqamli yuzi!</p>
                                        </div>
                                    </div>
                                    <div className="block-bottom-card">
                                        <div className="bottom-card">
                                            <TbSettingsSearch />
                                        </div>
                                        <div className="bottom-text">
                                            <h3>SEO</h3>
                                            <p>Google’da birinchi o‘rinda bo‘ling!</p>
                                        </div>
                                    </div>
                                    <div className="block-bottom-card">
                                        <div className="bottom-card">
                                            <GiServerRack />
                                        </div>
                                        <div className="bottom-text">
                                            <h3>Domen</h3>
                                            <p>Biznesingiz uchun mukammal nom!</p>
                                        </div>
                                    </div>
                                    <div className="block-bottom-card">
                                        <div className="bottom-card">
                                            <FaRobot />
                                        </div>
                                        <div className="bottom-text">
                                            <h3>Telegram Bot</h3>
                                            <p>Savdo va xizmatlarni avtomatlashtirishning  eng yaxshi usuli!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-two">
                                    <div className="section-block">
                                        <div data-aos="fade-up" data-aos-delay="80" className="block-text-cont">
                                            <h2>Biznesingiz uchun samarali raqamli yechimlar!</h2>
                                            <p>Telegram botlar va veb-saytlar orqali avtomatlashtirilgan va innovatsion echimlarni taqdim etamiz. Sizning muvaffaqiyatingiz uchun yangi darajani kashf eting!</p>
                                        </div>
                                        <div className="block-about-one">
                                            <div className="line"></div>
                                            <div data-aos="fade-right" className='one'>
                                                <div className='block-flex'>
                                                    <div className="icon">
                                                        <div className="icon-image"></div>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Telegram botlaringizni samarali va qulay rivojlantirishda yordam beramiz </h3>
                                                        <p>Biz mijozlaringiz uchun qulay va samarali yechimlar taqdim etamiz</p>
                                                    </div>
                                                </div>
                                                <div className='title-txt'>
                                                    <h2> <b>Avtomatlashtirish, soddalik va tezlik</b>  – bizning ustuvorligimiz! </h2>
                                                </div>
                                                <div className='title-desc'>
                                                    <h4>"Ustozweb" – bu mijozlaringiz bilan oson va samarali aloqa qilish uchun maxsus ishlab chiqilgan Telegram botlarini yaratish bo'yicha ishonchli jamoa. </h4>
                                                </div>
                                            </div>
                                            <div data-aos="fade-left" data-aos-delay="80" className="one-image"></div>
                                            <div className="shadow-block"></div>
                                        </div>
                                        <div className="block-about-two">
                                            <div data-aos="fade-up-right" data-aos-delay="80" className="one-image"></div>
                                            <div className="shadow-block"></div>
                                            <div data-aos="fade-up-left" className='one'>
                                                <div className='block-flex'>
                                                    <div className="icon">
                                                        <div className="icon-image"></div>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Veb-saytlar orqali biznesingizni global darajaga olib chiqing </h3>
                                                        <p>Biznesingizni yangi ufqlarga olib chiqadigan zamonaviy yechimlar taklif etamiz.</p>
                                                    </div>
                                                </div>
                                                <div className='title-txt'>
                                                    <h2> <b>Mobil moslik va zamonaviy</b>  dizayn – bizning yechimlarimizda! </h2>
                                                </div>
                                                <div className='title-desc'>
                                                    <h4>"Ustozweb" – mijozlaringiz uchun qulay va jozibali veb-saytlar yaratib, biznesingizni yangi bozorga olib chiqadi.   </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-about-three">
                                            <div data-aos="fade-up" data-aos-delay="80" className='one'>
                                                <div className='block-flex'>
                                                    <div className="icon">
                                                        <div className="icon-image"></div>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Virtual dunyoga eshik ochamiz  </h3>
                                                        <p>Innovatsion texnologiyalar bilan virtual imkoniyatlarni kashf eting.</p>
                                                    </div>
                                                </div>
                                                <div className='title-txt'>
                                                    <h2> <b>Kelajak</b> bugun boshlanadi! </h2>
                                                </div>
                                                <div className='title-desc'>
                                                    <h4>"Ustozweb" innovatsion texnologiyalarni qo'llab-quvvatlab, siz uchun virtual va kengaytirilgan reallik (AR/VR) platformalarini yaratadi.</h4>
                                                </div>
                                            </div>
                                            <div data-aos="fade-down" data-aos-delay="80" className="one-image"></div>
                                            <div className="shadow-block"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-three">
                                    <div className="about-service">
                                        <div data-aos="fade-right" data-aos-delay="80" className="one-card" >
                                            <h2>Telegram Bot Yaratish Xizmati </h2>
                                            <p>"Ustozweb" jamoasi sizning biznesingiz uchun samarali va funksional Telegram botlarini yaratishda yordam beradi. Savdo, xizmat ko'rsatish va mijozlar bilan muloqotni avtomatlashtirishga qaratilgan innovatsion yechimlarni taklif etamiz. </p>
                                            <Link>Batafsil</Link>
                                        </div>
                                        <div data-aos="fade-up" data-aos-delay="80" className="two-card">
                                            <div className="ball"></div>
                                            <h2>Veb-sayt Yaratish Xizmati  </h2>
                                            <p>Biz mijozlarga mobil moslashuvchan, zamonaviy dizaynli va yuqori samarali veb-saytlar yaratib beramiz. Sizning biznesingiz uchun maxsus yechimlarni ishlab chiqamiz va qo'llab-quvvatlaymiz.  </p>
                                            <Link>Batafsil</Link>
                                        </div>
                                        <div className="card-bg-shadow"></div>
                                    </div>
                                    <div className="company-container">
                                        <div className="company-bg-shadow"></div>
                                    </div>
                                </div>
                                <div className={scroll ? "activeScrollTop" : "scroll-top"} onClick={onClickScrollTop}>
                                    <FaHandPointUp />
                                </div>
                            </div>
                            <Footer />
                        </>
                    )
            }

        </>
    )
}
