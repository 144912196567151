import React, { useState } from 'react';
import axios from 'axios';
import '../styles/footer.css';
import '../styles/footerMobile.css';
import { Link } from 'react-router-dom';

import { FaTelegram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { Alert } from '@mui/material';

export default function Footer() {

    const [message, setMessage] = useState('')
    const [feedback, setFeedback] = useState(null);

    const onsubmitMessageBot = async (event) => {
        event.preventDefault();
        const botToken = '7544049294:AAF-l3OPfBxF62gN4rKyULKtLRvfYMJC_d4';
        const chatId = '6980617506';
        const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

        if (!message.trim()) {
            setFeedback({ type: "error", text: "Iltimos, xabar matnini kiriting!" });
            hideFeedbackAfterTimeout();
            return;
        }

        try {
            const response = await axios.post(url, {
                chat_id: chatId,
                text: message,
            });

            if (response.status === 200) {
                setFeedback({ type: "success", text: "Xabar muvaffaqiyatli yuborildi!" });
                setMessage(""); // Inputni tozalash
            } else {
                setFeedback({ type: "error", text: "Xabar yuborishda xatolik yuz berdi!" });
            }

        } catch (error) {
            setFeedback({ type: "error", text: "Xatolik yuz berdi: " + error.message });
        }
        hideFeedbackAfterTimeout();
    }

    const hideFeedbackAfterTimeout = () => {
        setTimeout(() => {
            setFeedback(null); // Xabarni tozalash
        }, 3000); // 3000 ms = 3 soniya
    };

    return (
        <>
            <footer>
                <div className="footer-contact">
                    <div className="footer-contact-block">
                        <div className="start"></div>
                        <h2>Taklif va murojatlar uchun!</h2>
                        <div className="contact-block">
                            <div className="pairChart"></div>
                            <form action="">
                                <input type="text" value={message}
                                    onChange={(e) => setMessage(e.target.value)} placeholder="Taklif va murojatlar" />
                                <button type='submit' onClick={onsubmitMessageBot}>Yuborish</button>
                            </form>
                            {feedback && (
                                <Alert style={{
                                    marginTop: "35px",
                                    color: feedback.type === "success" ? "green" : "red",
                                    border: `1px solid ${feedback.type === "success" ? "green" : "red"}`
                                }} severity={feedback.type === "success" ? "success" : "error"}>{feedback.text}</Alert> 
                            )}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-company-about">
                        <div className="footer-company-logo"></div>
                        <p>
                            <b>Ustozweb</b> Sizning ishonchli hamkoringiz. Saytlar va Telegram botlar yaratish bo'yicha eng yaxshi xizmatlar.
                        </p>
                        <p>@Ustozweb</p>
                    </div>
                    <div className="footer-home">
                        <h3>Biz haqimizda</h3>
                        <Link>- Xizmatlarimiz</Link>
                        <Link>- Portfolio</Link>
                        <Link>- Mijozlar fikri</Link>
                        <Link>- Bog‘lanish</Link>
                    </div>
                    <div className="footerContact">
                        <h3>Aloqa uchun:</h3>
                        <p>Sizning g‘oyangizni ro‘yobga chiqarish uchun har doim tayyormiz.</p>
                        <Link to={'tel:+998507030123'}>+998-50-703-01-23</Link>
                    </div>
                    <div className="footer-message">
                        <Link target='_blank' to={'https://t.me/ustozweb/'} className="message-card"><FaTelegram /></Link>
                        <Link target='_blank' to={'https://github.com/sariqdasturchi/'} className="message-card"><FaGithub /></Link>
                        <Link target='_blank' to={'https://linkedin.com/in/suhrobbozorov/'} className="message-card"><FaLinkedin /></Link>
                        <Link target='_blank' to={'https://youtube.com/@sariqdasturchi/'} className="message-card"><FaYoutube /></Link>
                    </div>
                </div>
                <div className="footer-border">
                    <p>Copyright © 2024 UstozWeb. Barcha huquqlar himoyalangan</p>
                </div>
            </footer>
        </>
    )
}
