import { Link } from "react-router-dom";
import logo from '../imgs/logo.png';

import { CiMenuBurger } from "react-icons/ci";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


import '../styles/navbar.css';
import '../styles/navbarMobile.css'
import { useEffect, useState } from "react";
import { Offcanvas } from 'react-bootstrap';


export default function Navbar() {
    const [scrollNavbar, setScrollNavbar] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onScrollNavbar = () => {
        if (window.scrollY >= 20) {
            setScrollNavbar(true)
        } else {
            setScrollNavbar(false)
        }
    }

    // document scroll
    useEffect(() => {
        window.addEventListener('scroll', onScrollNavbar);
        // Tozalanish funksiyasi
        return () => {
            window.removeEventListener('scroll', onScrollNavbar);
        };
    }, []);

    // click href 

    const onClickHrefTelegram = () => {
        window.location.href = 'https://t.me/sariqdasturchi';
    } 

    const onclickHrefYoutube = () => {
        window.location.href = 'https://youtube.com/@sariqdasturchi'
    }

    return (
        <>
            <nav className={scrollNavbar ? "activeNavbar" : "nav"}>
                <div className="logo">
                    <a href="/">
                        <img src={logo} alt="Ustozweb-Logo" />
                        <span>Ustozweb</span>
                    </a>
                </div>
                <ul className="menu-list">
                    <Link to="/">Bosh sahifa</Link>
                    <Link to="/about">Biz haqimizda</Link>
                    <Link to="services">Xizmatlar</Link>
                    <div className="contact-btn">
                        <Link to={'/contact'}>Kontakt</Link>
                    </div>
                </ul>
                <div className="menu-mobile">
                    <FaYoutube onClick={onclickHrefYoutube} />
                    <FaTelegram onClick={onClickHrefTelegram} />
                    {
                        !show ?
                            <CiMenuBurger onClick={handleShow} />

                            : null
                    }
                </div>
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Ustozweb</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <nav className="modal-nav">
                            <ul className="modal-menu-list">
                                <Link to="/">Bosh sahifa</Link>
                                <Link to="/about">Biz haqimizda</Link>
                                <Link to="services">Xizmatlar</Link>
                            </ul>
                            <div className="contact-btn">
                                <Link to={'/contact'}>Kontakt</Link>
                            </div>
                        </nav>
                    </Offcanvas.Body>
                    {/* <Offcanvas.Footer>
                        <div className="modal-footer">
                            <p>Ustozeb 2024</p>
                        </div>
                    </Offcanvas.Footer> */}
                </Offcanvas>
            </nav>

        </>
    )
}
